import React, { useState } from "react";
// import mic from "./../../resources/images/mic.svg";
// import micOff from "./../../resources/images/micOff.svg";
import mic from "./../../resources/images/unmute.png";
import micOff from "./../../resources/images/mute.png";
import * as WebRTCHandler from "../../utils/webRTCHandler";
import { Tooltip } from "react-tooltip";

const MicButton = () => {
  const [isMicMuted, setIsMicMuted] = useState(false);

  const handleMicButtonPressed = () => {
    WebRTCHandler.toggleMic(isMicMuted);
    setIsMicMuted(!isMicMuted);
  };

  return (
    <div className="video_button_container" onClick={handleMicButtonPressed}>
      <Tooltip id="my-tooltip" />
      {/* <object
        ref={objectRef}
        type="image/svg+xml"
        data={isMicMuted ? micOff : mic}
      ></object> */}
      <img
        className="video_button_image small-img"
        src={isMicMuted ? micOff : mic}
        xmlns="http://www.w3.org/2000/svg"
        onClick={handleMicButtonPressed}
        data-tooltip-id="my-tooltip"
        data-tooltip-content={isMicMuted ? "Mic On" : "Mic Off"}
      />
    </div>
  );
};

export default MicButton;

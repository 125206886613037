import RecordRTC from "recordrtc";
import io from "socket.io-client";
import config from "../config";
import { setRoomId, setParticipants } from "../store/actions";
import store from "../store/store";
import * as webRTCHandler from "./webRTCHandler";

let socket = null;

export const connectWithSocketIOServer = () => {
  socket = io(config.chatApiServer);

  socket.on("connect", () => {
    console.log("Sucessfully connected with socket IO Server");
    // console.log(socket.id);
  });

  socket.on("room-id", (data) => {
    const { roomId } = data;
    store.dispatch(setRoomId(roomId));
  });

  socket.on("call-request", (data) => {
    const { roomId, toJid, toJidSocketId, fromUserName } = data;
    store.dispatch(setRoomId(roomId));
    console.log(
      `Incoming call request from ${fromUserName} to join room ${roomId}`
    );
    webRTCHandler.sendCallRequestAlert(fromUserName);
  });

  socket.on("room-update", (data) => {
    const { connectedUsers } = data;
    // console.log(connectedUsers);
    store.dispatch(setParticipants(connectedUsers));
  });

  socket.on("conn-prepare", (data) => {
    const { connUserSocketId } = data;
    webRTCHandler.prepareNewPeerConnection(connUserSocketId, false);

    //inform the user which just joined the room that we have prepared for incoming connection
    socket.emit("conn-init", { connUserSocketId: connUserSocketId });
  });

  socket.on("conn-signal", (data) => {
    webRTCHandler.handleSignalingData(data);
  });

  socket.on("conn-init", (data) => {
    const { connUserSocketId } = data;
    webRTCHandler.prepareNewPeerConnection(connUserSocketId, true);
  });

  socket.on("user-disconnected", (data) => {
    // console.log("disconnected called..");
    webRTCHandler.removePeerConnection(data);
  });
};

export const loggedIn = (jid, name) => {
  const data = { jid, name };
  socket.emit("logged-in", data);
};

export const disconnect = () => {
  // console.log("Disconnect socket  emit");
  socket.emit("user-disconnect");
};

export const isConnected = () => {
  return !(socket === null);
};

export const inviteToRoom = (
  identity,
  onlyAudio,
  fromJid = "",
  toJid = "",
  roomId = ""
) => {
  const data = {
    identity,
    onlyAudio,
    fromJid,
    toJid,
    roomId,
  };
  socket.emit("invite-to-room", data);
};

export const createNewRoom = (
  identity,
  onlyAudio,
  fromJid = "",
  toJid = "",
  cofferEmail = ""
) => {
  const data = {
    identity,
    onlyAudio,
    fromJid,
    toJid,
    cofferEmail,
  };
  socket.emit("create-new-room", data);
};

export const joinRoom = (identity, roomId, onlyAudio, fromJid, cofferEmail) => {
  const data = { roomId, identity, onlyAudio, fromJid, cofferEmail };
  socket.emit("join-room", data);
};

export const signalPeerData = (data) => {
  socket.emit("conn-signal", data);
};

const config = {
  // chatApiServer: "https://api.avchat.digicoffer.com",
  // cofferApiServer: "https://api.digicoffer.com",
  // xmppServer: "chat.vitacape.com",
  // transServer: "https://prod.utils.trans.digicoffer.com",

  chatApiServer: "https://devapi.testavchat.digicoffer.com",
  cofferApiServer: "https://apidev2.digicoffer.com",
  xmppServer: "devchat.vitacape.com",
  transServer: "https://devprod.utils.trans.digicoffer.com",

  // chatApiServer: "https://staging.api.avchat.digicoffer.com",
  // cofferApiServer: "https://api.staging.digicoffer.com",
  // xmppServer: "devchat.vitacape.com",
  // transServer: "https://devprod.utils.trans.digicoffer.com",

  // pchatApiServer: "https://localhost:9000",
  // pcofferApiServer: "https://api.digicoffer.com",
  // pxmppServer: "chat.vitacape.com",
  // ptransServer: "https://prod.utils.trans.digicoffer.com",
};

export default config;

import Actions from "./actions";

const initState = {
  identity: "", //jid in the future
  isRoomHost: false,
  connectOnlyWithAudio: false,
  roomId: null,
  showOverlay: true,
  participants: [],
  cofferToken: "",
  cofferAuthData: {},
  fromJid: "",
  toJid: "",
  inRequest: false,
  callFrom: "",
  showMenu: false,
  showParticipant: false,
  showPopUsers: false,
  showChat: false,
  connection: [],
  team: [],
  clientUsers: [],
  messages: [],
  loginType: "",
  isRecordingOn: false,
  showError: false,
  cofferEmail: "",
  hideClient: false,
};

const reducer = (state = initState, action) => {
  switch (action.type) {
    case Actions.SET_IS_ROOM_HOST:
      return { ...state, isRoomHost: action.isRoomHost };
    case Actions.SET_RECORDING_STATUS:
      return { ...state, isRecordingOn: action.isRecordingOn };
    case Actions.SET_SHOW_ERROR:
      return { ...state, showError: action.showError };
    case Actions.SET_CONNECT_ONLY_WITH_AUDIO:
      return { ...state, connectOnlyWithAudio: action.onlyWithAudio };
    case Actions.SET_ROOM_ID:
      return { ...state, roomId: action.roomId };
    case Actions.SET_IDENTITY:
      return { ...state, identity: action.identity };
    case Actions.SET_SHOW_OVERLAY:
      return { ...state, showOverlay: action.showOverlay };
    case Actions.SET_PARTICIPANTS:
      return { ...state, participants: action.participants };
    case Actions.SET_COFFER_TOKEN:
      return { ...state, cofferToken: action.cofferToken };
    case Actions.SET_COFFER_AUTH_DATA:
      return { ...state, cofferAuthData: action.cofferAuthData };
    case Actions.SET_FROM_JID:
      return { ...state, fromJid: action.fromJid };
    case Actions.SET_TO_JID:
      return { ...state, toJid: action.toJid };
    case Actions.SET_IN_REQUEST:
      return { ...state, inRequest: action.inRequest };
    case Actions.SET_CALL_FROM:
      return { ...state, callFrom: action.callFrom };
    case Actions.SET_SHOW_MENU:
      return { ...state, showMenu: action.showMenu };
    case Actions.SET_SHOW_PARTICIPANT:
      return { ...state, showParticipant: action.showParticipant };
    case Actions.SET_SHOW_POPUSERS:
      return { ...state, showPopUsers: action.showPopUsers };
    case Actions.SET_CONNECTION:
      return { ...state, connection: action.connection };
    case Actions.SET_CONNECTION_USERS:
      return { ...state, clientUsers: action.clientUsers };
    case Actions.SET_TEAM:
      return { ...state, team: action.team };
    case Actions.SET_SHOW_CHAT:
      return { ...state, showChat: action.showChat };
    case Actions.SET_MESSAGES:
      return { ...state, messages: action.messages };
    case Actions.SET_LOGIN_TYPE:
      return { ...state, loginType: action.loginType };
    case Actions.SET_COFFER_EMAIL:
      return { ...state, cofferEmail: action.cofferEmail };
    case Actions.SET_HIDE_CLIENT:
      return { ...state, hideClient: action.hideClient };
    default:
      return state;
  }
};

export default reducer;

const Actions = {
  SET_IS_ROOM_HOST: "SET_IS_ROOM_HOST",
  SET_CONNECT_ONLY_WITH_AUDIO: "SET_CONNECT_ONLY_WITH_AUDIO",
  SET_ROOM_ID: "SET_ROOM_ID",
  SET_IDENTITY: "SET_IDENTITY",
  SET_SHOW_OVERLAY: "SET_SHOW_OVERLAY",
  SET_PARTICIPANTS: "SET_PARTICIPANTS",
  SET_COFFER_TOKEN: "SET_COFFER_TOKEN",
  SET_COFFER_AUTH_DATA: "SET_COFFER_AUTH_DATA",
  SET_FROM_JID: "SET_FROM_JID",
  SET_TO_JID: "SET_TO_JID",
  SET_IN_REQUEST: "SET_IN_REQUEST",
  SET_CALL_FROM: "SET_CALL_FROM",
  SET_SHOW_MENU: "SET_SHOW_MENU",
  SET_SHOW_PARTICIPANT: "SET_SHOW_PARTICIPANT",
  SET_SHOW_POPUSERS: "SET_SHOW_POPUSERS",
  SET_CONNECTION: "SET_CONNECTION",
  SET_TEAM: "SET_TEAM",
  SET_SHOW_CHAT: "SET_SHOW_CHAT",
  SET_MESSAGES: "SET_MESSAGES",
  SET_LOGIN_TYPE: "SET_LOGIN_TYPE",
  SET_CONNECTION_USERS: "SET_CONNECTION_USERS",
  SET_RECORDING_STATUS: "SET_RECORDING_STATUS",
  SET_SHOW_ERROR: "SET_SHOW_ERROR",
  SET_COFFER_EMAIL: "SET_COFFER_EMAIL",
  SET_HIDE_CLIENT: "SET_HIDE_CLIENT",
};

export const setIsRoomHost = (isRoomHost) => {
  return {
    type: Actions.SET_IS_ROOM_HOST,
    isRoomHost,
  };
};

export const setConnectOnlyWithAudio = (onlyWithAudio) => {
  return {
    type: Actions.SET_CONNECT_ONLY_WITH_AUDIO,
    onlyWithAudio,
  };
};

export const setIdentity = (identity) => {
  return {
    type: Actions.SET_IDENTITY,
    identity,
  };
};

export const setRoomId = (roomId) => {
  return {
    type: Actions.SET_ROOM_ID,
    roomId,
  };
};

export const setParticipants = (participants) => {
  return {
    type: Actions.SET_PARTICIPANTS,
    participants,
  };
};

export const setShowOverlay = (showOverlay) => {
  return {
    type: Actions.SET_SHOW_OVERLAY,
    showOverlay,
  };
};

export const setCofferToken = (cofferToken) => {
  return {
    type: Actions.SET_COFFER_TOKEN,
    cofferToken,
  };
};

export const setCofferAuthData = (cofferAuthData) => {
  return {
    type: Actions.SET_COFFER_AUTH_DATA,
    cofferAuthData,
  };
};

export const setFromJid = (fromJid) => {
  return {
    type: Actions.SET_FROM_JID,
    fromJid,
  };
};

export const setToJid = (toJid) => {
  return {
    type: Actions.SET_TO_JID,
    toJid,
  };
};

export const setInRequest = (inRequest) => {
  return {
    type: Actions.SET_IN_REQUEST,
    inRequest,
  };
};

export const setCallFrom = (callFrom) => {
  return {
    type: Actions.SET_CALL_FROM,
    callFrom,
  };
};

export const setShowMenu = (showMenu) => {
  return {
    type: Actions.SET_SHOW_MENU,
    showMenu,
  };
};

export const setShowParticipant = (showParticipant) => {
  return {
    type: Actions.SET_SHOW_PARTICIPANT,
    showParticipant,
  };
};

export const setShowPopUsers = (showPopUsers) => {
  return {
    type: Actions.SET_SHOW_POPUSERS,
    showPopUsers,
  };
};

export const setConnection = (connection) => {
  return {
    type: Actions.SET_CONNECTION,
    connection,
  };
};

export const setClientUsers = (clientUsers) => {
  return {
    type: Actions.SET_CONNECTION_USERS,
    clientUsers,
  };
};

export const setTeam = (team) => {
  return {
    type: Actions.SET_TEAM,
    team,
  };
};

export const setShowChat = (showChat) => {
  return {
    type: Actions.SET_SHOW_CHAT,
    showChat,
  };
};

export const setMessages = (messages) => {
  return {
    type: Actions.SET_MESSAGES,
    messages,
  };
};

export const setLoginType = (loginType) => {
  return {
    type: Actions.SET_LOGIN_TYPE,
    loginType,
  };
};

export const setIsRecordingOn = (isRecordingOn) => {
  return {
    type: Actions.SET_RECORDING_STATUS,
    isRecordingOn,
  };
};

export const setShowError = (showError) => {
  return {
    type: Actions.SET_SHOW_ERROR,
    showError,
  };
};

export const setCofferEmail = (cofferEmail) => {
  return {
    type: Actions.SET_COFFER_EMAIL,
    cofferEmail,
  };
};

export const setHideClient = (hideClient) => {
  return {
    type: Actions.SET_HIDE_CLIENT,
    hideClient,
  };
};

export default Actions;

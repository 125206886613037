import React, { useState } from "react";
// import cameraOnImg from "./../../resources/images/camera.svg";
// import cameraOffImg from "./../../resources/images/cameraOff.svg";
import cameraOnImg from "./../../resources/images/camera.png";
import cameraOffImg from "./../../resources/images/cameraoff.png";
import * as WebRTCHandler from "../../utils/webRTCHandler";
import { Tooltip } from "react-tooltip";

const CameraButton = () => {
  const [isLocalVideDisabled, setIsLocalVideoDisabled] = useState(false);

  const handleCameraButtonPressed = () => {
    WebRTCHandler.toggleCamera(isLocalVideDisabled);
    setIsLocalVideoDisabled(!isLocalVideDisabled);
  };
  return (
    <div className="video_button_container">
      <Tooltip id="my-tooltip" />
      <img
        className="video_button_image small-img"
        src={isLocalVideDisabled ? cameraOffImg : cameraOnImg}
        xmlns="http://www.w3.org/2000/svg"
        onClick={handleCameraButtonPressed}
        data-tooltip-id="my-tooltip"
        data-tooltip-content={isLocalVideDisabled ? "Camera On" : "Camera Off"}
      />
    </div>
  );
};

export default CameraButton;

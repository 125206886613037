import React, { useState } from "react";
import { getCofferLogin } from "../utils/api";
import {
  setCofferToken,
  setCofferAuthData,
  setIdentity,
  setFromJid,
  setLoginType,
  setHideClient,
} from "../store/actions";
import { connect } from "react-redux";
import ErrorMessage from "../joinroompage/ErrorMessage";
import { useNavigate } from "react-router-dom";
import * as webRTCHandler from "./../utils/webRTCHandler";
import config from "../config";
import "./login.css";
import mic from "../resources/images/mic.svg";

const Login = (props) => {
  const navigate = useNavigate();
  const {
    setCofferTokenAction,
    setCofferAuthDataAction,
    setIdentityAction,
    setFromJidAction,
    setLoginTypeAction,
    setHideClientAction,
  } = props;
  //const [cofferToken, setCofferToken] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [loginValue, setLoginValue] = useState("");
  const [loginType, setLoginType] = useState("pro"); //Added to support Pro and Individual
  const [passwordValue, setPasswordValue] = useState("");
  const handleEmailValueChange = (event) => {
    setLoginValue(event.target.value);
  };

  const handlePasswordValueChange = (event) => {
    setPasswordValue(event.target.value);
  };

  const handleLoginTypeChange = (event) => {
    setLoginType(event.target.value);
    setLoginTypeAction(event.target.value);
    // console.log(event.target.value);
  };

  const handleLogin = async (event) => {
    let hideClient = false;
    event.preventDefault();
    const responseMessage = await getCofferLogin(
      loginValue,
      passwordValue,
      "lauditor",
      loginType
    );
    const { error, token, data } = responseMessage;

    if (!error) {
      const jid = `${
        loginType === "ind"
          ? data.uid + "@" + config.xmppServer
          : data.uid + "_" + data.user_id + "@" + config.xmppServer
      }`;
      const fullName =
        loginType === "ind"
          ? data.first_name + " " + data.last_name
          : data.name;
      webRTCHandler.sendLoggedInInfo(jid, fullName);
      setCofferTokenAction(token);
      setCofferAuthDataAction(data);
      setIdentityAction(fullName);
      setFromJidAction(jid);
      if (loginType === "pro") {
        hideClient =
          data.groups.length === 1 && data.groups[0] === "AAM" ? true : false;
        setHideClientAction(hideClient);
      }
      navigate(
        `/?logintype=${encodeURIComponent(
          loginType
        )}&token=${token}&jid=${encodeURIComponent(
          jid
        )}&name=${encodeURIComponent(fullName)}&hideclient=${hideClient}`
      );
    } else {
      const { msg } = responseMessage;
      setErrorMessage(getMessages(msg));
      // console.log(msg);
    }
  };

  const getMessages = (jsonObject) => {
    let msg = "";
    for (const key in jsonObject) {
      msg += jsonObject[key] + "\n";
    }
    return msg;
  };

  return (
    <div className="login-container">
      <div className="login-card">
        <h2>Login</h2>
        <form>
          <div className="form-group">
            <label htmlFor="loginType">Login type</label>
            <select
              id="loginType"
              name="loginType"
              value={loginType}
              onChange={handleLoginTypeChange}
              required
            >
              <option value="pro">Professional</option>
              <option value="ind">Individual</option>
            </select>
          </div>
          <div className="form-group">
            <label htmlFor="username">Email</label>
            <input
              type="email"
              id="username"
              placeholder="Email"
              name="username"
              value={loginValue}
              onChange={handleEmailValueChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="password">Password</label>
            <input
              type="password"
              id="password"
              name="password"
              placeholder="Password"
              value={passwordValue}
              onChange={handlePasswordValueChange}
              required
            />
          </div>
          {/* <!-- <div className="form-group">
            <input type="checkbox" id="remember_me" name="remember_me" />
            <label htmlFor="remember_me">Remember Me</label>
          </div> --> */}
          <ErrorMessage errorMessage={errorMessage} />
          <button onClick={handleLogin}>Login</button>
        </form>
      </div>
    </div>
  );
};

const mapStoreStateToProps = (state) => {
  return {
    ...state,
  };
};

const mapActionsToProps = (dispatch) => {
  return {
    setCofferTokenAction: (cofferToken) =>
      dispatch(setCofferToken(cofferToken)),
    setCofferAuthDataAction: (cofferAuthData) =>
      dispatch(setCofferAuthData(cofferAuthData)),
    setIdentityAction: (identity) => dispatch(setIdentity(identity)),
    setFromJidAction: (fromJid) => dispatch(setFromJid(fromJid)),
    setLoginTypeAction: (loginType) => dispatch(setLoginType(loginType)),
    setHideClientAction: (hideClient) => dispatch(setHideClient(hideClient)),
  };
};

export default connect(mapStoreStateToProps, mapActionsToProps)(Login);
